// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-healthcare-contact-us-js": () => import("./../../../src/pages/healthcare-contact-us.js" /* webpackChunkName: "component---src-pages-healthcare-contact-us-js" */),
  "component---src-pages-healthcare-mission-culture-js": () => import("./../../../src/pages/healthcare-mission-culture.js" /* webpackChunkName: "component---src-pages-healthcare-mission-culture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovations-insights-js": () => import("./../../../src/pages/innovations-&-insights.js" /* webpackChunkName: "component---src-pages-innovations-insights-js" */),
  "component---src-pages-pharmaceutical-advertising-agencies-js": () => import("./../../../src/pages/pharmaceutical-advertising-agencies.js" /* webpackChunkName: "component---src-pages-pharmaceutical-advertising-agencies-js" */),
  "component---src-pages-pharmaceutical-awards-js": () => import("./../../../src/pages/pharmaceutical-awards.js" /* webpackChunkName: "component---src-pages-pharmaceutical-awards-js" */)
}

